import React, { useEffect } from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';

const LinkError = () => {
  const [, setToken] = useLocalStorage('authToken', null);

  useEffect(() => {
    setToken(null);
  }, [setToken]);

  return <></>;
};

export default LinkError;
